import emotion from '@emotion/styled'

import {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFile, faFire} from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/system';
import DivComponent from './DivComponent';

const Dropzone = styled(DivComponent)(({theme}) => `
    background-color: ${(theme.palette.mode === "dark")?"#555":"#EEE"};
    outline-style: dashed;
    outline-color: #888;
    outline-width: 3px;
    width: 100%;
`);

const CenteredText = emotion.div`
    text-align: center;
    padding: 50px;
    color: #888;
`;

export interface FileDropperProps {
    onNewFiles: (files: File[]) => void
}

export const FileDropper = (props: FileDropperProps) => {
    const onDrop = useCallback(acceptedFiles => {
        props.onNewFiles(acceptedFiles);
    }, [props]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <section className="container">
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <CenteredText>
            {
                isDragActive ?
                <p><FontAwesomeIcon icon={faFire}/> Drop it like it's hot! <FontAwesomeIcon icon={faFire}/></p> :
                <p><FontAwesomeIcon icon={faFile}/> Drag 'n' drop some files here, click to select files, or Ctrl+V that good stuff <br/> (click anywhere in the dialog before pasting).</p>
            }
        </CenteredText>
      </Dropzone>
    </section>
  );
};

