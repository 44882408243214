import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Avatar, Paper } from "@mui/material";
import { SyntheticEvent, useRef } from "react";
import { Asset, AssetFileType } from "../Interfaces/Asset";
import { faCube } from "@fortawesome/free-solid-svg-icons";

import { Box, styled } from "@mui/system";
import AssetFileItem from "./AssetFileItem";
import AssetViewButton from "./AssetViewButton";

const URLRegex = /^https?:\/\//gi;

const SmallGridItem = styled(Grid)`
    cursor: pointer;
`;

const StyledPaper = styled(Paper)(
    ({ theme }) => `
    padding: ${theme.spacing(2)};
    min-width: calc(${theme.spacing(2)} + ${theme.spacing(2)} + 300px);
`
);

function capitalize(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
}

export interface AssetItemProps {
    asset: Asset;
    onChange: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
    expanded: boolean;
}

export default function AssetItem(props: AssetItemProps) {
    let imageSrc: string = ""; // FIND DEFAULT IMAGE

    let url = "";
    if (props.asset.url) {
        url = props.asset.url;
        if (!url.match(URLRegex)) {
            url = "https://" + url;
        }
    }

    let modelFiles: JSX.Element[] = [];
    let imageFiles: JSX.Element[] = [];
    let slicerFiles: JSX.Element[] = [];
    let otherFiles: JSX.Element[] = [];

    for (let file of props.asset.files) {
        if (file.type === AssetFileType.Image && imageSrc === "") {
            imageSrc = file.location;
        }

        let first = false;
        switch (file.type) {
            case AssetFileType.Model:
                first = modelFiles.length < 1;
                modelFiles.push(<AssetFileItem key={file.id} file={file} first={first} />);
                break;
            case AssetFileType.Image:
                first = imageFiles.length < 1;
                imageFiles.push(<AssetFileItem key={file.id} file={file} first={first} />);
                break;
            case AssetFileType.Slicer:
                first = slicerFiles.length < 1;
                slicerFiles.push(<AssetFileItem key={file.id} file={file} first={first} />);
                break;
            default:
                first = otherFiles.length < 1;
                otherFiles.push(<AssetFileItem key={file.id} file={file} first={first} />);
        }
    }

    let viewButton = useRef<HTMLButtonElement>(null);

    return (
        <StyledPaper>
            <Grid direction="column" justifyContent="left" container>
                <SmallGridItem
                    onClick={() => {
                        if (viewButton.current) viewButton.current?.click();
                    }}
                    item>
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
                        <Avatar src={imageSrc} variant="rounded" sx={{ width: 300, height: 300 }}>
                            <FontAwesomeIcon size="3x" icon={faCube} />
                        </Avatar>
                    </Box>
                </SmallGridItem>
                <Grid xs item spacing={2} justifyContent="space-between" container>
                    <Grid item>
                        <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                            <p>
                                <b>{props.asset.name}</b>
                                <br />
                                {capitalize(props.asset.category)}
                            </p>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                            {props.asset.files.length} Files
                        </Box>
                    </Grid>
                </Grid>
                <Grid item>
                    <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                        <AssetViewButton asset={props.asset} buttonRef={viewButton} />
                    </Box>
                </Grid>
            </Grid>
        </StyledPaper>
    );
}
