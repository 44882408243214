import { CircularProgress, Grid } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { FileHelper } from '../Helpers/FileHelper'



export interface UploadViewFileProps {
    file: FileHelper
}

export default function UploadViewFile(props: UploadViewFileProps) {
    const StyledGrid = styled(Grid)(({theme})=>`
        padding: ${theme.spacing(1)};
    `);

    const [progress, setProgress] = useState(props.file.uploadProgress);

    useEffect(() => {
        let subscription = props.file.subscribe(() => {
            setProgress(props.file.uploadProgress);
        });

        return () => {
            props.file.unsubscribe(subscription);
        }
    }, [props])

    let fileName = props.file.getName() + "." + props.file.getExtension();
    if (fileName.length > 32){
        fileName = fileName.substr(fileName.length-32);
    }

    return (
        <StyledGrid direction="row" spacing={2} justifyContent="space-between" container>
            <Grid item>
                <CircularProgress variant="determinate" size={24} value={progress}/>
            </Grid>
            <Grid xs item>
                {fileName}
            </Grid>
            <Grid item>
                {humanFileSize(props.file.file.size)}
            </Grid>
        </StyledGrid>
    )
}

/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use 
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
 function humanFileSize(bytes: number, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}