import React from 'react'

export interface DivComponentProps {
    children?: React.ReactNode;
    className?: string;
    [key:string]: any;
}

export default function DivComponent(props: DivComponentProps) {
  return (
    <div {...props}>{props.children}</div>
  )
}
