import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUserNinja } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress, Grid, TextField, Tooltip } from "@mui/material";
import CategorySelect from "../Components/CategorySelect";
import { FileDropper } from "../Components/FileDropper";
import FileEditItem from "../Components/FileUploadItem";
import { AssetCategory, AssetFile, Asset } from "../Interfaces/Asset";
import { FileHelper } from "../Helpers/FileHelper";
import ConfirmDialog from "./ConfirmDialog";
import APIHelper from "../Helpers/APIHelper";
import { Box } from "@mui/system";
import axios from "axios";
import UploadFolderDialog from "./UploadFolderDialog";
const apiHelper = APIHelper.getInstance();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogContent = styled(DialogContent)(
    ({ theme }) => `
    min-width: 700px;
`
);

const BootstrapTextField = styled(TextField)`
    width: 100%;
`;

export interface AssetUploadDialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: AssetUploadDialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <FontAwesomeIcon icon={faClose} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export interface AssetUploadDialogProps {
    asset: Asset;
    open: boolean;
    title?: string;
    onCancel: () => void;
    onSave: () => void;
    onChange: (asset: Asset) => void;
}

export default function AssetUploadDialog(props: AssetUploadDialogProps) {
    const { open, onCancel, onSave } = props;

    let [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
    const [isStealinOg, setIsStealinOg] = React.useState(false);

    // Dealing with folder upload dialog open/closed
    const [folderDialogOpen, setFolderDialogOpen] = React.useState(false);
    const [folderDialogAssets, setFolderDialogAssets] = React.useState<Array<AssetFile>>([]);

    const onFolderUpload = (folderName: string, assets: Array<AssetFile>) => {
        setFolderDialogOpen(false);

        if (folderName !== "") {
            let newFiles = props.asset.files;

            assets.forEach((file) => {
                newFiles = newFiles.map((element) => {
                    if (element.id === file.id) {
                        let newFile = { ...element };
                        newFile.name = folderName + "/" + file.name;
                        return newFile;
                    }

                    return element;
                });
            });

            props.onChange({
                ...props.asset,
                files: newFiles
            });
        }
    };

    const handleClose = () => {
        setConfirmDialogOpen(true);
    };

    const confirmDialogCancelClick = () => {
        setConfirmDialogOpen(false);
    };

    const confirmDialogConfirmClick = () => {
        // TODO remove any created files from the server
        setConfirmDialogOpen(false);
        onCancel();
    };

    const handleSave = () => {
        onSave();
    };

    const onNewFiles = async (newFiles: File[]) => {
        let newFilteredFiles: File[] = newFiles.filter((file: File) => {
            let index = props.asset.files.findIndex((existingFile) => {
                if (existingFile.helper) {
                    return existingFile.helper.file.name === file.name;
                }

                return false;
            });

            if (index === -1) {
                return true;
            }

            return false;
        });

        let newCreatedFiles = newFilteredFiles.map<Promise<AssetFile>>(async (file: File) => {
            let newFileHelper = new FileHelper(file);
            let newAssetFile = await newFileHelper.upload();

            return {
                ...newAssetFile,
                helper: newFileHelper
            };
        });

        Promise.all(newCreatedFiles).then((newFiles: AssetFile[]) => {
            // Add the new file to the files list, including its associated helper
            props.onChange({
                ...props.asset,
                files: [...props.asset.files, ...newFiles]
            });

            // Uploading multiple files, check-in if this should be a folder
            if (newFiles.length > 1) {
                setFolderDialogAssets(newFiles);
                setFolderDialogOpen(true);
            }
        });
    };

    const onRemoveFile = (id: number) => () => {
        props.onChange({
            ...props.asset,
            files: props.asset.files.filter((file) => file.id !== id)
        });
    };

    const onFileNameChange = (id: number) => (name: string) => {
        let newFiles = props.asset.files.map((file) => {
            if (file.id === id) {
                let newFile = { ...file };
                newFile.name = name;
                return newFile;
            }

            return file;
        });

        props.onChange({
            ...props.asset,
            files: newFiles
        });
    };

    const onChangeName = (newName: string) => {
        props.onChange({
            ...props.asset,
            name: newName
        });
    };

    const onChangeUrl = (newUrl: string) => {
        props.onChange({
            ...props.asset,
            url: newUrl
        });
    };

    const onOgSteal = () => {
        if (props.asset.url) {
            setIsStealinOg(true);
            apiHelper
                .getUrlOgData(props.asset.url)
                .then((result) => {
                    if (result.title) {
                        if (props.asset.name === "") {
                            props.onChange({
                                ...props.asset,
                                name: result.title
                            });
                        }
                    }

                    if (result.image) {
                        axios({
                            url: result.image,
                            method: "GET",
                            responseType: "blob" // important
                        }).then((response) => {
                            let splitUrl = result.image!.split("/");
                            let filename = splitUrl[splitUrl.length - 1];
                            let imageFile = new File([response.data], filename);

                            onNewFiles([imageFile]);
                        });
                    }
                })
                .finally(() => {
                    setIsStealinOg(false);
                });
        }
    };

    const onChangeCategory = (newCategory: AssetCategory) => {
        props.onChange({
            ...props.asset,
            category: newCategory
        });
    };

    const onPasteFile = (event: React.ClipboardEvent<HTMLElement>) => {
        let files: File[] = [];
        console.log("pasted something");
        if (event.clipboardData.files.length > 0) {
            console.log(`pasted ${event.clipboardData.files.length} files`);
            for (let i = 0; i < event.clipboardData.files.length; i++) {
                let file = event.clipboardData.files.item(i);
                if (file !== null) files.push(file);
            }
        }

        if (files.length > 0) {
            onNewFiles(files);
        }
    };

    let fileComponents: JSX.Element = (
        <Grid direction="column" spacing={2} container>
            {props.asset.files.map<JSX.Element>((file) => {
                return (
                    <Grid key={"file-" + file.id} item>
                        <FileEditItem
                            file={file}
                            onRemove={onRemoveFile(file.id)}
                            onChange={onFileNameChange(file.id)}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                onClick={(event) => {
                    event.stopPropagation();
                }}
                onPaste={onPasteFile}
                open={open}
                maxWidth="lg">
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title ? props.title : "New asset"}
                </BootstrapDialogTitle>
                <BootstrapDialogContent>
                    <Grid direction="column" spacing={2} container>
                        <Grid item>
                            <BootstrapTextField
                                id="name-field"
                                label="Asset name"
                                variant="outlined"
                                margin="dense"
                                value={props.asset.name}
                                onChange={(event) => {
                                    onChangeName(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <CategorySelect
                                value={props.asset.category}
                                onChange={(val) => {
                                    onChangeCategory(val);
                                }}
                            />
                        </Grid>
                        <Grid spacing={2} item container>
                            <Grid xs item>
                                <BootstrapTextField
                                    id="url-field"
                                    label="URL (optional)"
                                    variant="outlined"
                                    margin="dense"
                                    value={props.asset.url}
                                    onChange={(event) => {
                                        onChangeUrl(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                                    {isStealinOg ? (
                                        <CircularProgress variant="indeterminate" size={38} />
                                    ) : (
                                        <Tooltip
                                            title="Steal image and/or title from this URL (title only works when no asset name has been entered)"
                                            arrow>
                                            <IconButton onClick={onOgSteal}>
                                                <FontAwesomeIcon icon={faUserNinja} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <FileDropper onNewFiles={onNewFiles} />
                            <UploadFolderDialog
                                open={folderDialogOpen}
                                assets={folderDialogAssets}
                                onCancel={() => {
                                    setFolderDialogOpen(false);
                                }}
                                onConfirm={onFolderUpload}
                            />
                        </Grid>
                        {props.asset.files.length > 0 ? (
                            <Grid item>
                                <h2>Files</h2>
                                {fileComponents}
                            </Grid>
                        ) : undefined}
                    </Grid>
                </BootstrapDialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" autoFocus onClick={handleSave}>
                        Save asset
                    </Button>
                </DialogActions>
            </BootstrapDialog>
            <ConfirmDialog
                open={confirmDialogOpen}
                title="Are you sure?"
                text="If you close this window now you will lose your progress. Are you sure you want to close this window?"
                onCancel={confirmDialogCancelClick}
                onConfirm={confirmDialogConfirmClick}
            />
        </React.Fragment>
    );
}
