import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Grid, Paper, TextField } from '@mui/material'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { styled } from '@mui/system'
import CategorySelect from '../Components/CategorySelect'
import { AssetCategory } from '../Interfaces/Asset'
import APIHelper from '../Helpers/APIHelper'
import GoogleLoginComponent from '../Components/LoginComponent'
import AssetUploadButton from '../Components/AssetUploadButton'
const apiHelper = APIHelper.getInstance();

const WideButton = styled(Button)`
    width: 100%;
`;

const BootstrapTextField = styled(TextField)`
    width: 100%;
    backgroundColor: "white";
`;

const BootstrapPaper = styled(Paper)(({theme}) => `
    padding: ${theme.spacing(2)};
`);

export default function SearchFormView() {

    const [categoryFilter, setCategoryFilter] = useState(AssetCategory.All);
    const [search, setSearch] = useState("");

    const onCategoryChange = (newCategory: AssetCategory) => {
        setCategoryFilter(newCategory);
    }

    const onSearch = () => {
        apiHelper.searchAssets(search, categoryFilter);
    }

    const handleKeypress = (event: React.KeyboardEvent) => {
        //it triggers by pressing the enter key
      if (event.key === "Enter") {
        onSearch();
      }
    };

    return (
        <BootstrapPaper>
            <Grid direction="column" spacing={2} container>
                <Grid item>
                    <GoogleLoginComponent/>
                </Grid>
                <Grid item>
                    <AssetUploadButton/>
                </Grid>
                <Grid item>
                    <BootstrapTextField value={search} onKeyPress={handleKeypress} onChange={(event) => { setSearch(event.target.value) }} id="standard-basic" label="Search" variant="outlined" />
                </Grid>
                <Grid item>
                    <CategorySelect value={categoryFilter} onChange={onCategoryChange} hasAllOption />
                </Grid>
                <Grid item>
                    <WideButton variant="outlined" onClick={onSearch}><FontAwesomeIcon icon={faSearch} /> &nbsp; Search</WideButton>
                </Grid>
            </Grid>
        </BootstrapPaper>

    )
}
