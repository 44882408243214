import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import AssetUploadDialog from "../Dialogs/AssetUploadDialog";
import { Asset, AssetCategory, AssetFile } from "../Interfaces/Asset";
import APIHelper from "../Helpers/APIHelper";
const apiHelper = APIHelper.getInstance();

const WideButton = styled(Button)`
  width: 100%;
`;

export default function AssetUploadButton() {
  const [asset, setAsset] = useState<Asset>({
    name: "",
    category: AssetCategory.Creature,
    files: [],
    url: "",
  });

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const onAssetDialogCancel = () => {
    setUploadDialogOpen(false);
    setAsset({
      name: "",
      category: AssetCategory.Creature,
      files: [],
    });
  };

  const onAssetDialogSave = () => {
    let fileIds = asset.files.map<number>((file: AssetFile) => {
      return file.id;
    });

    apiHelper.updateFiles(asset.files).then(() => {
      apiHelper.createAsset(asset.name, asset.category, asset.url!, fileIds);
    });

    setUploadDialogOpen(false);
    setAsset({
      name: "",
      category: AssetCategory.Creature,
      files: [],
    });
  };

  const openAssetDialog = () => {
    setUploadDialogOpen(true);
  };

  const onAssetChange = (newAsset: Asset) => {
    console.log(newAsset);
    setAsset(newAsset);
  };

  return (
    <React.Fragment>
      <WideButton variant="contained" onClick={openAssetDialog}>
        <FontAwesomeIcon icon={faUpload} /> &nbsp; Upload
      </WideButton>
      <AssetUploadDialog
        asset={asset}
        open={uploadDialogOpen}
        onCancel={onAssetDialogCancel}
        onSave={onAssetDialogSave}
        onChange={onAssetChange}
      />
    </React.Fragment>
  );
}
