import { Paper } from '@mui/material';
import { styled } from '@mui/system'
import GoogleLoginComponent from '../Components/LoginComponent';

const BootstrapPaper = styled(Paper)(({ theme }) => `
    width: 400px;
    padding: ${theme.spacing(2)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`);

export default function LoginView() {
    return (
        <div>
            <BootstrapPaper>
                <h2>Models DB</h2>
                <p>This application is private, if you have not been invited you will not be able to use this application.</p>
                <GoogleLoginComponent/>
            </BootstrapPaper>
        </div>
    )
}
