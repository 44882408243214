import { useState, Fragment } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Grid, Avatar } from "@mui/material";
import { getFilesAndFolders } from "../Components/FileFolder";
import AssetFileItem from "../Components/AssetFileItem";
import AssetEditButton from "../Components/AssetEditButton";
import { Asset, AssetFileType } from "../Interfaces/Asset";
import { Box } from "@mui/system";
import { faFile, faCube, faPizzaSlice, faImage } from "@fortawesome/free-solid-svg-icons";
import ImageDialog from "./ImageDialog";

const URLRegex = /^https?:\/\//gi;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogContent = styled(DialogContent)(
    ({ theme }) => `
    min-width: 700px;
`
);

const SmallGridItem = styled(Grid)`
    width: 168px;
`;

const EditButtonGridItem = styled(SmallGridItem)(
    ({ theme }) => `
    padding-right: ${theme.spacing(2)}
`
);

function capitalize(val: string) {
    return val.charAt(0).toUpperCase() + val.slice(1);
}

export interface AssetDialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: AssetDialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <FontAwesomeIcon icon={faClose} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export interface AssetDialogProps {
    asset: Asset;
    open: boolean;
    title: string;
    onCancel: () => void;
}

export default function AssetDialog(props: AssetDialogProps) {
    let handleClose = () => {
        props.onCancel();
    };

    let imageSrc: string = ""; // FIND DEFAULT IMAGE

    let url = "";
    if (props.asset.url) {
        url = props.asset.url;
        if (!url.match(URLRegex)) {
            url = "https://" + url;
        }
    }

    let modelFiles: JSX.Element[] = [];
    let imageFiles: JSX.Element[] = [];
    let slicerFiles: JSX.Element[] = [];
    let otherFiles: JSX.Element[] = [];

    for (let file of props.asset.files) {
        if (file.type === AssetFileType.Image && imageSrc === "") {
            imageSrc = file.location;
        }

        let first = false;
        switch (file.type) {
            case AssetFileType.Model:
                first = modelFiles.length < 1;
                modelFiles.push(<AssetFileItem key={file.id} file={file} first={first} />);
                break;
            case AssetFileType.Image:
                first = imageFiles.length < 1;
                imageFiles.push(<AssetFileItem key={file.id} file={file} first={first} />);
                break;
            case AssetFileType.Slicer:
                first = slicerFiles.length < 1;
                slicerFiles.push(<AssetFileItem key={file.id} file={file} first={first} />);
                break;
            default:
                first = otherFiles.length < 1;
                otherFiles.push(<AssetFileItem key={file.id} file={file} first={first} />);
        }
    }

    let elements = getFilesAndFolders(props.asset.files, "");

    let filesTable: JSX.Element;

    // If there are any folders, render them as folders. Otherwise, use category view.
    if (elements.folders.length > 0) {
        filesTable = (
            <Fragment>
                {elements.folders.length > 0 ? elements.folders : undefined}
                {elements.folders.length > 0 ? elements.files : undefined}
            </Fragment>
        );
    } else {
        filesTable = (
            <Fragment>
                {props.asset.url ? (
                    <p>
                        URL:{" "}
                        <a href={url} target="_blank" rel="noreferrer">
                            {url}
                        </a>
                    </p>
                ) : undefined}
                {modelFiles.length > 0 ? (
                    <h3>
                        <FontAwesomeIcon icon={faCube} /> &nbsp; Models
                    </h3>
                ) : undefined}
                {modelFiles.length > 0 ? modelFiles : undefined}

                {slicerFiles.length > 0 ? (
                    <h3>
                        <FontAwesomeIcon icon={faPizzaSlice} /> &nbsp; Slicer scenes
                    </h3>
                ) : undefined}
                {slicerFiles.length > 0 ? slicerFiles : undefined}

                {imageFiles.length > 0 ? (
                    <h3>
                        <FontAwesomeIcon icon={faImage} /> &nbsp; Images
                    </h3>
                ) : undefined}
                {imageFiles.length > 0 ? imageFiles : undefined}

                {otherFiles.length > 0 ? (
                    <h3>
                        <FontAwesomeIcon icon={faFile} /> &nbsp; Other
                    </h3>
                ) : undefined}
                {otherFiles.length > 0 ? otherFiles : undefined}
            </Fragment>
        );
    }

    const [ImageDialogOpen, setImageDialogOpen] = useState(false);
    const onImageClick = (event: React.MouseEvent) => {
        setImageDialogOpen(true);
    };

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby={`dialog-${props.title}`}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                open={props.open}
                maxWidth="lg">
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title}
                </BootstrapDialogTitle>
                <BootstrapDialogContent>
                    <Grid direction="column" spacing={2} container>
                        <Grid direction="row" justifyContent="left" item container>
                            <SmallGridItem item>
                                <Avatar
                                    src={imageSrc}
                                    onClick={onImageClick}
                                    variant="rounded"
                                    sx={{ width: 150, height: 150 }}>
                                    <FontAwesomeIcon size="2x" icon={faCube} />
                                </Avatar>
                                <ImageDialog
                                    imageSrc={imageSrc}
                                    open={ImageDialogOpen}
                                    title={props.asset.name}
                                    onCancel={() => {
                                        setImageDialogOpen(false);
                                    }}
                                />
                            </SmallGridItem>
                            <Grid xs item>
                                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                                    <p>{capitalize(props.asset.category)}</p>
                                </Box>
                            </Grid>
                            <SmallGridItem item>
                                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                                    {props.asset.files.length} Files
                                </Box>
                            </SmallGridItem>
                            <EditButtonGridItem item>
                                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                                    <AssetEditButton asset={props.asset} />
                                </Box>
                            </EditButtonGridItem>
                        </Grid>
                        <Grid item>{filesTable}</Grid>
                    </Grid>
                </BootstrapDialogContent>
            </BootstrapDialog>
        </Fragment>
    );
}
