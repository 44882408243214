import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import AssetItem from "../Components/AssetItem";
import useInfiniteScroll from "react-infinite-scroll-hook";

import APIHelper from "../Helpers/APIHelper";
import { Box, styled } from "@mui/system";
const apiHelper = APIHelper.getInstance();

const LoadingBox = styled(Box)`
    width: 100%;
    height: 200px;
`;

export default function AssetsListView() {
    const [expanded, setExpanded] = useState<number>(-1);
    const [assets, setAssets] = useState(apiHelper.assets);
    const [loading, setLoading] = useState(apiHelper.isSearching);

    useEffect(() => {
        let subscription = apiHelper.subscribe(() => {
            setLoading(apiHelper.isSearching);
            setAssets(apiHelper.assets);
        });

        return () => {
            apiHelper.unsubscribe(subscription);
        };
    }, [assets, setAssets, loading, setLoading]);

    const loadMore = () => {
        console.log("Loading more items...");
        apiHelper.nextPage();
    };

    const [sentryRef] = useInfiniteScroll({
        loading: loading,
        hasNextPage: apiHelper.hasMorePages,
        onLoadMore: loadMore,
        delayInMs: 200,
        // `rootMargin` is passed to `IntersectionObserver`.
        // We can use it to trigger 'onLoadMore' when the sentry comes near to become
        // visible, instead of becoming fully visible on the screen.
        rootMargin: "0px 0px 300px 0px"
    });

    const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : -1);
    };

    let assetList = assets.map<JSX.Element>((asset) => {
        return (
            <Grid key={asset.id} xs item>
                <AssetItem asset={asset} onChange={handleChange(asset.id!)} expanded={expanded === asset.id} />
            </Grid>
        );
    });

    let loadingElement = (
        <LoadingBox ref={sentryRef} display="flex" justifyContent="center" alignItems="center" minHeight="100%">
            <CircularProgress variant="indeterminate" size={48} />
        </LoadingBox>
    );

    return (
        <div>
            <Grid spacing={2} container>
                {assetList}
            </Grid>

            {apiHelper.hasMorePages ? loadingElement : undefined}
        </div>
    );
}
