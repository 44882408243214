import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import React, { useRef, useState } from "react";
import AssetUploadDialog from "../Dialogs/AssetUploadDialog";
import { Asset, AssetFile } from "../Interfaces/Asset";
import APIHelper from "../Helpers/APIHelper";
import AssetDialog from "../Dialogs/AssetDialog";
const apiHelper = APIHelper.getInstance();

const WideButton = styled(Button)`
    width: 100%;
`;

export interface AssetViewButtonProps {
    asset: Asset;
    buttonRef: React.RefObject<HTMLButtonElement>;
}

export default function AssetViewButton(props: AssetViewButtonProps) {
    const [asset, setAsset] = useState<Asset>({ ...props.asset });

    const [dialogOpen, setDialogOpen] = useState(false);

    const onAssetDialogCancel = () => {
        setDialogOpen(false);
    };

    const onAssetDialogSave = () => {
        setDialogOpen(false);

        apiHelper.updateFiles(asset.files).then(() => {
            apiHelper.updateAsset(asset);
        });
    };

    const openAssetDialog = () => {
        setDialogOpen(true);
    };

    const onAssetChange = (newAsset: Asset) => {
        setAsset(newAsset);
    };

    return (
        <React.Fragment>
            <WideButton
                ref={props.buttonRef}
                variant="contained"
                onClick={(event) => {
                    event.stopPropagation();
                    openAssetDialog();
                }}>
                <FontAwesomeIcon icon={faMagnifyingGlass} /> &nbsp; View
            </WideButton>
            <AssetDialog title={asset.name} asset={asset} open={dialogOpen} onCancel={onAssetDialogCancel} />
        </React.Fragment>
    );
}
