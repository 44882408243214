import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system'
import { useEffect, useState } from 'react'
import UploadViewFile from '../Components/UploadViewFile';
import { FileHelper } from '../Helpers/FileHelper';
import { FileUploader } from '../Helpers/FileUploader';

const uploader = FileUploader.getInstance();

const UploadView = styled(Paper)`
    width: 400px;
    position: fixed;
    bottom: 0;
    right: 24px;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
    max-height: 500px;
    overflow: auto;
`;

const StyledAccordionSummary = styled(AccordionSummary)(({theme}) => `
    background-color: ${theme.palette.secondary.main}
`);

export default function UploadProgressView() {
    //    const [visible, setVisible] = useState(uploader.files.length > 0);
    const [visible, setVisible] = useState(uploader.uploadQueue.length > 0);
    const [files, setFiles] = useState<FileHelper[]>([]);

    useEffect(() => {
        let subscription = uploader.subscribe(() => {
            setVisible(uploader.uploadQueue.length > 0);
            setFiles(uploader.uploadQueue);
        })

        return () => {
            uploader.unsubscribe(subscription);
        }
    }, []);

    return (
        <div>
            {(visible) ?
                <UploadView>
                    <Accordion >
                        <StyledAccordionSummary
                            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                            aria-controls="upload-progress-content"
                            id="upload-progress-header"
                        >
                            <Grid direction="row" spacing={2} container>
                                <Grid item>
                                    <CircularProgress size={22} color="primary" variant="indeterminate" />
                                </Grid>
                                <Grid item>
                                    <Typography>{files.length} Files being uploaded</Typography>
                                </Grid>

                            </Grid>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            {files.map<JSX.Element>(element => <UploadViewFile key={element.fileId} file={element} />)}
                        </StyledAccordionDetails>
                    </Accordion>
                </UploadView>
                : undefined}
        </div>
    )
}
