import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { AssetCategory } from '../Interfaces/Asset';

export interface CategorySelectProps {
    value: string;
    hasAllOption?: boolean;
    onChange: (newValue: AssetCategory) => void;
}

export default function CategorySelect(props: CategorySelectProps) {
    const { value, onChange, hasAllOption } = props;

    const handleChange = (event: SelectChangeEvent) => {
        onChange(event.target.value as AssetCategory);
    };

    // Get the categories from the AssetCategory enum
    let categoryItems: JSX.Element[] = Object.entries(AssetCategory).filter(([key, category]) => {
        return (!hasAllOption && category === AssetCategory.All)?false:true;
    }).map<JSX.Element>(([key, category]) => {
        return <MenuItem key={key} value={category}>{key}</MenuItem>;
    });

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="select-category-label">Category</InputLabel>
                <Select
                    labelId="select-category-label"
                    value={value}
                    label="Category"
                    onChange={handleChange}
                >
                    {categoryItems}

                </Select>
            </FormControl>
        </Box>
    );
}