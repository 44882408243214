import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import { AssetFile } from "../Interfaces/Asset";

export interface UploadFolderDialogProps {
    open: boolean;
    assets: Array<AssetFile>;
    onConfirm: (folderName: string, assets: Array<AssetFile>) => void;
    onCancel: () => void;
}

export default function UploadFolderDialog(props: UploadFolderDialogProps) {
    const [folderName, setFolderName] = React.useState("");

    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            onClick={(event) => {
                event.stopPropagation();
            }}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description">
            <DialogTitle id="confirm-dialog-title">Upload to folder?</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                    Multi file upload detected, upload these files to a folder? <br /> (Leave empty or cancel for no
                    folder)
                </DialogContentText>
                <br />
                <TextField
                    label="Folder name"
                    variant="outlined"
                    value={folderName}
                    onChange={(event) => {
                        setFolderName(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} autoFocus>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        props.onConfirm(folderName, props.assets);
                    }}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
