import { Grid, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchFormView from './SearchFormView';
import AssetsListView from './AssetsList';
import DivComponent from '../Components/DivComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faDatabase, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import UploadProgressView from './UploadProgressView';
import { Box, useTheme } from '@mui/system';
import { ColorModeContext } from "../Contexts/ColorModeContext";

const MainContainerGrid = styled(Grid)(({ theme }) => `
    height: 100vh;
    padding: ${theme.spacing(2)};
`);

const MenuGridItem = styled(Grid)(({ theme }) => `
    height: 100%;
    min-width: 400px;
    padding-right: ${theme.spacing(2)}
`);

const AssetViewGridItem = styled(Grid)(({ theme }) => `
    
`);

const GridHeaderSpacer = styled(DivComponent)(({ theme }) => `
    height: 72px;
    display: block;
    font-size: 3em;
    font-weight: bold;
`);

const StyledBox = styled(Box)(({theme}) => `
    width: 100%;
    padding: ${theme.spacing(2)}
`);

export function SearchView() {
    const colorMode = React.useContext(ColorModeContext);
    const theme = useTheme();

    return (
        <React.Fragment>
            <MainContainerGrid spacing={0} justifyContent="left" container>
                <MenuGridItem xs={2} item>
                    <GridHeaderSpacer>
                        <FontAwesomeIcon icon={faCube} /> <FontAwesomeIcon icon={faDatabase} /> Models DB
                    </GridHeaderSpacer>
                    <SearchFormView />
                    <StyledBox
                        textAlign="center">
                        <FontAwesomeIcon icon={faMoon} />
                        <Switch color="secondary" checked={theme.palette.mode==="light"} onClick={colorMode.toggleColorMode}/>
                        <FontAwesomeIcon icon={faSun} />
                    </StyledBox>
                </MenuGridItem>
                <AssetViewGridItem xs item zeroMinWidth>
                    <GridHeaderSpacer />
                    <AssetsListView />
                </AssetViewGridItem>
            </MainContainerGrid>
            <UploadProgressView />
        </React.Fragment>
    )
}
