import { FileHelper } from "./FileHelper";
import { AssetFile, FileUploadStatus } from "../Interfaces/Asset";
import axios from "axios";
import APIHelper from "../Helpers/APIHelper";
import { v4 as uuidv4 } from "uuid"

const apiHelper = APIHelper.getInstance();

export class FileUploader {
    private static instance: FileUploader;
    private _files: FileHelper[] = [];
    private _uploadQueue: { targetUrl: string; fileHelper: FileHelper; }[] = [];
    public get uploadQueue(): FileHelper[] {
        if (this._currentFile){
            return [
                this._currentFile,
                ...this._uploadQueue.map<FileHelper>(element => element.fileHelper)
            ];
        } else {
            return [
                ...this._uploadQueue.map<FileHelper>(element => element.fileHelper)
            ];
        }
        
    }


    private _currentFile?: FileHelper = undefined;

    private _subscribers: { [subId: string]: () => void } = {};
    private updateSubscribers(): void {
        for (const [, callback] of Object.entries(this._subscribers)) {
            callback();
        }
    }

    subscribe(callback: () => void): string {
        let subId = uuidv4();
        this._subscribers[subId] = callback;
        return subId;
    }

    unsubscribe(subId: string) {
        delete this._subscribers[subId];
    }

    public get files(): FileHelper[] {
        return this._files;
    }

    private constructor() {
    }

    static getInstance(): FileUploader {
        if (this.instance !== undefined) {
            return this.instance;
        } else {
            this.instance = new FileUploader();
            return this.instance;
        }
    }

    /**
     * Starts file upload and binds helper to API file id
     * @param file 
     */
    async uploadFile(fileHelper: FileHelper): Promise<AssetFile> {
        this._files.push(fileHelper);
        let fileData = await apiHelper.createFile(fileHelper.getName(), fileHelper.getExtension(), fileHelper.getType(), FileUploadStatus.QUEUED, fileHelper.file.size);
        fileHelper.bind(fileData.id);

        if (this._currentFile === undefined) {
            this.startFileUpload(fileData.uploadTarget, fileHelper);
        } else {
            this._uploadQueue.push({
                targetUrl: fileData.uploadTarget,
                fileHelper: fileHelper
            });
        }

        this.updateSubscribers();

        return fileData;
    }

    private async startFileUpload(uploadTarget: string, fileHelper: FileHelper) {
        this._currentFile = fileHelper;

        axios.put(uploadTarget, fileHelper.file, {
            headers: {
                'Content-Type': 'application/octet-stream'
            },
            onUploadProgress: (event: ProgressEvent) => {
                let progress = Math.round((event.loaded / event.total) * 100);
                if (progress < 100) {
                    fileHelper.uploadStatusUpdate(progress, FileUploadStatus.UPLOADING);
                }
            }
        }).then((result) => {
            fileHelper.uploadStatusUpdate(100, FileUploadStatus.DONE);
            apiHelper.fileUploadComplete(fileHelper.fileId);
        })/*.catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        })*/.finally(() => {
            // Pop the first item from the queue and start upload
            let uploadOperation = this._uploadQueue.shift();
            if (uploadOperation !== undefined) {
                this.startFileUpload(uploadOperation.targetUrl, uploadOperation.fileHelper);
            } else {
                this._currentFile = undefined;
            }

            this.updateSubscribers();
        });
    }
}