import { Fragment } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const BootstrapDialogContent = styled(DialogContent)(
    ({ theme }) => `
    min-width: 700px;
`
);

const StyledImg = styled("img")(
    ({ theme }) => `
    width: 100%;
`
);

export interface ImageDialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: ImageDialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <FontAwesomeIcon icon={faClose} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export interface ImageDialogProps {
    imageSrc: string;
    open: boolean;
    title: string;
    onCancel: () => void;
}

export default function ImageDialog(props: ImageDialogProps) {
    let handleClose = () => {
        props.onCancel();
    };

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby={`dialog-${props.title}`}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                open={props.open}
                maxWidth="lg">
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {props.title}
                </BootstrapDialogTitle>
                <BootstrapDialogContent>
                    <StyledImg src={props.imageSrc} />
                </BootstrapDialogContent>
            </BootstrapDialog>
        </Fragment>
    );
}
