import { SearchView } from "./Views/SearchView";
import AccountHelper from './Helpers/AccountHelper';
import LoginView from "./Views/LoginView";

import { createTheme, ThemeOptions, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { ColorModeContext } from "./Contexts/ColorModeContext";

const accountHelper = new AccountHelper();

let lightTheme: ThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: '#3498db',
        },
        secondary: {
            main: '#db7734',
        },
        background:{
            default: "#ecf0f1"
        }
    },
};

const darkTheme: ThemeOptions = {
    palette: {
        mode: "dark",
        primary: {
            main: '#3498db',
        },
        secondary: {
            main: '#db7734',
        },
    },
}

if (localStorage.getItem("colorMode") === ""){
    localStorage.setItem("colorMode", "dark");
}

const getLSColorMode = () => {
    if (localStorage.getItem('colorMode') === "light"){
        return "light";
    } else {
        return "dark"
    }
}

function App() {
    const [mode, setMode] = React.useState<'light' | 'dark'>(getLSColorMode());
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => {
                    let newMode: "light"|"dark" = (prevMode === 'light' ? 'dark' : 'light');
                    localStorage.setItem("colorMode", newMode);
                    return newMode;
                });
            },
        }),
        [],
    );

    const theme = React.useMemo(
        () =>
            createTheme((mode === "dark")?darkTheme:lightTheme),
        [mode],
    );

    return (
        <div className="App">
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {(accountHelper.isLoggedIn()) ? <SearchView /> : <LoginView />}
                </ThemeProvider>
            </ColorModeContext.Provider>
        </div>
    );
}

export default App;
