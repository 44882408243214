import axios from "axios";
import { Asset, AssetCategory, AssetFile, FileUploadStatus } from "../Interfaces/Asset";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";

import AccountHelper from "../Helpers/AccountHelper";
const accountHelper = new AccountHelper();

const APIUrl = "https://api2.tabletop.cloud/v1/modelsdb";
//const APIUrl = "http://localhost:8090/v1/modelsdb"

export default class APIHelper {
    private static _instance: APIHelper;
    private _lastSearch: { searchTerm: string; category: AssetCategory } = {
        searchTerm: "",
        category: AssetCategory.All
    };

    private _hasMorePages: boolean = false;
    public get hasMorePages(): boolean {
        return this._hasMorePages;
    }

    constructor() {
        // Start out with an empty seach
        this.searchAssets("");
    }

    static getInstance(): APIHelper {
        if (!this._instance) {
            this._instance = new APIHelper();
        }

        return this._instance;
    }

    private _assets: Asset[] = [];
    public get assets(): Asset[] {
        return this._assets;
    }

    private _isSearching: boolean = true;
    public get isSearching(): boolean {
        return this._isSearching;
    }

    private _subscribers: { [subId: string]: () => void } = {};
    private updateSubscribers(): void {
        for (const [, callback] of Object.entries(this._subscribers)) {
            callback();
        }
    }

    subscribe(callback: () => void): string {
        let subId = uuidv4();
        this._subscribers[subId] = callback;
        return subId;
    }

    unsubscribe(subId: string) {
        delete this._subscribers[subId];
    }

    searchAssets(searchTerm: string, category: AssetCategory = AssetCategory.All) {
        this._lastSearch = {
            searchTerm: searchTerm,
            category: category
        };

        this._isSearching = true;
        this.updateSubscribers();

        let request: { searchTerm: string; category?: AssetCategory } = {
            searchTerm
        };

        if (category !== AssetCategory.All) {
            request = {
                ...request,
                category: category
            };
        }

        axios
            .post(APIUrl + "/search", request, {
                headers: {
                    authorization: "Bearer " + accountHelper.getToken()
                }
            })
            .then((result) => {
                this._isSearching = false;
                this._assets = result.data as Asset[];
                if (this._assets.length < 20) {
                    this._hasMorePages = false;
                } else {
                    this._hasMorePages = true;
                }

                this.updateSubscribers();
            })
            .catch((error) => {
                this._isSearching = false;
                this.updateSubscribers();
            });
    }

    nextPage() {
        this._isSearching = true;
        this.updateSubscribers();

        let request: { searchTerm: string; category?: AssetCategory; lastItemId?: number } = {
            searchTerm: this._lastSearch.searchTerm,
            lastItemId: this.assets[this.assets.length - 1].id
        };

        if (this._lastSearch.category !== AssetCategory.All) {
            request = {
                ...request,
                category: this._lastSearch.category
            };
        }

        axios
            .post(APIUrl + "/search", request, {
                headers: {
                    authorization: "Bearer " + accountHelper.getToken()
                }
            })
            .then((result) => {
                let newAssets = result.data as Asset[];

                if (newAssets.length < 20) {
                    this._hasMorePages = false;
                } else {
                    this._hasMorePages = true;
                }

                this._isSearching = false;

                this._assets = [...this.assets, ...newAssets];
                this.updateSubscribers();
            })
            .catch((error) => {
                this._isSearching = false;
                this.updateSubscribers();
            });
    }

    refresh() {
        this.searchAssets(this._lastSearch.searchTerm, this._lastSearch.category);
    }

    createAsset(name: string, category: AssetCategory, url: string, fileIds: number[]) {
        return new Promise<void>((resolve, reject) => {
            axios
                .post(
                    APIUrl + "/asset",
                    {
                        name: name,
                        category: category as string,
                        files: fileIds,
                        url: url
                    },
                    {
                        headers: {
                            authorization: "Bearer " + accountHelper.getToken()
                        }
                    }
                )
                .then((result) => {
                    resolve(result.data);
                    this.refresh();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    createFile(name: string, extension: string, type: string, status: number, size: number) {
        return new Promise<FileCreateResponse>((resolve, reject) => {
            axios
                .post(
                    APIUrl + "/file",
                    {
                        name: name,
                        extension: extension,
                        type: type,
                        status: status,
                        size: size
                    },
                    {
                        headers: {
                            authorization: "Bearer " + accountHelper.getToken()
                        }
                    }
                )
                .then((result) => {
                    resolve(result.data as FileCreateResponse);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    updateFiles(updatedFiles: Array<AssetFile>) {
        // TODO check if there were changes and only execute when there were?
        return new Promise<void>((resolve, reject) => {
            let fileUpdates = updatedFiles.map<{ id: number; name: string; status: FileUploadStatus }>((file) => {
                return {
                    id: file.id,
                    name: file.name,
                    status: file.helper ? file.helper.uploadStatus : file.status
                };
            });

            axios
                .put(APIUrl + "/files", fileUpdates, {
                    headers: {
                        authorization: "Bearer " + accountHelper.getToken()
                    }
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    fileUploadComplete(fileId: number) {
        return new Promise<void>((resolve, reject) => {
            let fileUpdates = [
                {
                    id: fileId,
                    status: FileUploadStatus.DONE
                }
            ];

            axios
                .put(APIUrl + "/files", fileUpdates, {
                    headers: {
                        authorization: "Bearer " + accountHelper.getToken()
                    }
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    updateAsset(newAsset: Asset) {
        // TODO check if there were changes and only execute when there were?
        return new Promise<void>((resolve, reject) => {
            let fileIds = newAsset.files.map<number>((file) => file.id);

            let assetUpdates = {
                ...newAsset,
                files: fileIds
            };

            axios
                .put(APIUrl + "/asset/" + newAsset.id, assetUpdates, {
                    headers: {
                        authorization: "Bearer " + accountHelper.getToken()
                    }
                })
                .then((result) => {
                    this.refresh();
                    resolve(result.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getUrlOgData(url: string): Promise<OGResponse> {
        // TODO check if there were changes and only execute when there were?
        return new Promise<OGResponse>((resolve, reject) => {
            axios
                .get(APIUrl + "/opengraph/" + Buffer.from(url).toString("hex"), {
                    headers: {
                        authorization: "Bearer " + accountHelper.getToken()
                    }
                })
                .then((result) => {
                    resolve(result.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}

export interface FileCreateResponse extends AssetFile {
    updatedAt: string;
    createdAt: string;
    uploadTarget: string;
}

export interface OGResponse {
    title?: string;
    image?: string;
}
