import { Grid, IconButton, LinearProgress, Paper, TextField } from '@mui/material'
import { Box, styled } from '@mui/system'
import { useEffect, useState } from 'react'
import { faPizzaSlice, faCube, faImage, faFile, faClose } from '@fortawesome/free-solid-svg-icons'
import DivComponent from './DivComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { AssetFile, AssetFileType, FileUploadStatus } from '../Interfaces/Asset';

const BootstrapPaper = styled(Paper)`
  text-align: center;
`;

const BootstrapContainer = styled(DivComponent)(({ theme }) => `
  margin: ${theme.spacing(2)}
`);

const NameTextField = styled(TextField)`
  width: 100%;
`;

export interface FileUploadItemProps {
    file: AssetFile,
    onChange: (name: string) => void,
    onRemove: () => void
}

export default function FileEditItem(props: FileUploadItemProps) {
    let { file, onRemove } = props;
    let [state, setState] = useState({
        uploadProgress: (file.helper)?file.helper.uploadProgress:100,
        uploadStatus: (file.helper)?file.helper.uploadStatus:file.status
    });

    useEffect(()=>{
        let subscription: string|undefined;
        if (file.helper){
            subscription = file.helper.subscribe(() => {
                setState({
                    ...state,
                    uploadProgress: file.helper?.uploadProgress!,
                    uploadStatus: file.helper?.uploadStatus!
                });
            });
        }
        

        return () => {
            if (subscription){
                file.helper?.unsubscribe(subscription);
            }
        }
    }, [file, state]);

    let icon: IconDefinition;

    // Select the right icon for this file type
    switch (file.type) {
        case AssetFileType.Image:
            icon = faImage;
            break;
        case AssetFileType.Model:
            icon = faCube;
            break;
        case AssetFileType.Slicer:
            icon = faPizzaSlice;
            break;
        case AssetFileType.Other:
            icon = faFile;
            break;
        default:
            icon = faFile;
            break;
    }

    let statusText = "";
    switch (state.uploadStatus) {
        case FileUploadStatus.QUEUED:
            statusText = "QUEUED";
            break;
        case FileUploadStatus.UPLOADING:
            statusText = `UPLOADING ${state.uploadProgress}%`;
            break;
        case FileUploadStatus.DONE:
            statusText = "DONE";
            break;
        case FileUploadStatus.FAILED:
            statusText = "FAILED";
            break;
        default:
            statusText = "ERROR"
    }

    return (
        <BootstrapPaper>
            <BootstrapContainer>
                <Grid direction="row" alignContent="center" justifyContent="space-between" spacing={2} container>
                    <Grid xs={1} alignContent="center" item>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100%">
                            <FontAwesomeIcon icon={icon} />
                        </Box>
                    </Grid>
                    <Grid xs={7} alignContent="center" item>
                        <NameTextField label="Filename" size="small" variant="standard" value={file.name} onChange={(event) => {props.onChange(event.target.value)}}/>
                    </Grid>
                    <Grid xs={3} alignContent="center" item>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100%">
                            {statusText}
                        </Box>
                    </Grid>
                    <Grid xs={1} alignContent="center" item>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            minHeight="100%">
                            <IconButton onClick={onRemove}>
                                <FontAwesomeIcon icon={faClose} />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </BootstrapContainer>
            <LinearProgress color="secondary" variant="determinate" value={state.uploadProgress} />
        </BootstrapPaper>
    )
}
