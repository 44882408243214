import { faChevronDown, faFolder } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { Box, styled } from '@mui/system'
import React from 'react'
import { AssetFile } from '../Interfaces/Asset'
import AssetFileItem from './AssetFileItem'

const StyledAccordionDetails = styled(AccordionDetails)`
    padding-left: 48px;
`;

export interface FileFolderProps {
    title?: string,
    files: AssetFile[],
    root?: string
}

export function getFilesAndFolders(ogFiles: AssetFile[], root: string): {files: JSX.Element[], folders: JSX.Element[]} {
    let folders: {[key: string]: AssetFile[]} = {};
    let files: AssetFile[] = [];

    ogFiles.forEach(file => {
        let fileName = file.name.replace(root, "");
        let name = fileName.split("/");

        if (name.length > 1){
            if (folders[name[0]]){
                folders[name[0]].push(file);
            } else {
                folders[name[0]] = [file];
            }
        } else {
            files.push(file);
        }
    });

    let folderElements: JSX.Element[] = [];
    for (const [key, value] of Object.entries(folders)){
        folderElements.push(<FileFolder key={key} title={key} files={value} root={root+key+'/'}/>)
    }

    return {
        files: files.map<JSX.Element>(file => (<AssetFileItem key={file.id} file={file} shortName={true}/>)),
        folders: folderElements
    }
}

export function FileFolder(props: FileFolderProps) {
    let root = props.root || ""; 

    let data = getFilesAndFolders(props.files, root);

    return (
        <Accordion disableGutters square>
            <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
                <Box
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                    minHeight="100%">
                    <FontAwesomeIcon icon={faFolder} /> &nbsp; {props.title}
                </Box>
            </AccordionSummary>
            <StyledAccordionDetails>
                {data.folders}
                {data.files}
            </StyledAccordionDetails>
        </Accordion>
    )
}
