import { FileHelper } from "../Helpers/FileHelper";

export interface Asset {
    id?: number,
    name: string,
    category: AssetCategory,
    files: AssetFile[],
    url?: string,
    tags?: string[]
}

export interface AssetFile {
    id: number,
    uid: string,
    status: FileUploadStatus,
    name: string,
    location: string,
    type: AssetFileType,
    extension: string,
    size: number,
    helper?: FileHelper
}

export enum FileUploadStatus {
    QUEUED = 0,
    UPLOADING = 1,
    DONE = 2,
    FAILED = 3
}

export enum AssetFileType {
    Model = "model",
    Image = "image",
    Slicer = "slicer",
    Other = "other"
}

export enum AssetCategory {
    All = "all",
    Creature = "creature",
    Terrain = "terrain",
    Prop = "prop",
    Character = "character",
    Bases = "bases",
    Statues = "statues",
    Other = "other"
}