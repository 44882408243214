import axios from "axios";
import { Buffer } from "buffer";

/**
 * Returns whether the user has a valid token or not
 * @returns
 */

export default class AccountHelper {
    isLoggedIn() {
        let user = this.getUser();

        if (user) {
            let expiryDate = new Date(user.exp * 1000);
            if (expiryDate <= new Date()) return false;

            return true;
        } else {
            return false;
        }
    }

    /**
     * Returns the data stored in the token.
     * @returns
     */
    getUser() {
        let token = this.getToken();
        if (token !== "") {
            let tokenSplit = token.split(".");
            if (tokenSplit.length !== 3) return false;

            let jsonString = Buffer.from(tokenSplit[1], "base64").toString();
            let json = JSON.parse(jsonString);

            return json ? json : false;
        } else {
            return false;
        }
    }

    /**
     * Find the token
     * @returns
     */
    getToken(): string {
        if (localStorage.getItem("token") && localStorage.getItem("token") !== "") {
            return localStorage.getItem("token") as string;
        } else if (this.getCookieValue("tc_token") !== "" && this.getCookieValue("tc_token") !== undefined) {
            return this.getCookieValue("tc_token") as string;
        } else {
            return "";
        }
    }

    logOut() {
        localStorage.removeItem("token");
    }

    refreshToken() {
        let user = this.getUser();

        if (this.isLoggedIn() && user) {
            let expiryDate = new Date(user.exp * 1000);
            expiryDate.setDate(expiryDate.getDate() - 3);

            // If the token will be expiring within 3 days, renew it.
            if (expiryDate <= new Date()) {
                axios.get("https://api.tabletop.cloud/account/refresh?tc_token=" + this.getToken()).then(
                    (response) => {
                        // Save the new token to the local storage
                        if (response.data.token) {
                            localStorage.setItem("token", response.data.token);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        }
    }

    getCookieValue(a: string) {
        let b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
        return b ? b.pop() : "";
    }
}
