import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import { Box, styled } from "@mui/system";
import { faDownload, faFile, faCube, faPizzaSlice, faImage } from "@fortawesome/free-solid-svg-icons";
import { AssetFile, AssetFileType } from "../Interfaces/Asset";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import React, { useState } from "react";
import axios from "axios";
import ImageDialog from "../Dialogs/ImageDialog";

const DownloadButtonGrid = styled(Grid)`
    width: 64px;
`;

export interface AssetFileItemProps {
    file: AssetFile;
    first?: boolean;
    shortName?: boolean;
}

export default function AssetFileItem(props: AssetFileItemProps) {
    const [isDownloading, setIsDownloading] = useState(false);

    let name = props.file.name + "." + props.file.extension;
    if (props.shortName) {
        let splitName = props.file.name.split("/");
        name = splitName[splitName.length - 1] + "." + props.file.extension;
    }

    const BootstrapGrid = styled(Grid)`
        color: #888;
        width: 100%;
        min-height: 48px;
        ${!props.first ? "border-top: 1px solid rgba(0, 0, 0, .125);" : ""}
    `;

    let icon: IconDefinition;

    // Select the right icon for this file type
    switch (props.file.type) {
        case AssetFileType.Image:
            icon = faImage;
            break;
        case AssetFileType.Model:
            icon = faCube;
            break;
        case AssetFileType.Slicer:
            icon = faPizzaSlice;
            break;
        case AssetFileType.Other:
            icon = faFile;
            break;
        default:
            icon = faFile;
            break;
    }

    const onDownload = (event: React.MouseEvent) => {
        event.stopPropagation();

        setIsDownloading(true);
        axios
            .get(props.file.location, {
                responseType: "blob",
                headers: {
                    "Cache-Control": "no-cache",
                    Pragma: "no-cache",
                    Expires: "0"
                }
            })
            .then((result) => {
                let data = result.data as Blob;

                // Download the file to the user
                const url = window.URL.createObjectURL(data);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                // the filename you want
                a.download = props.file.name + "." + props.file.extension;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }) /*.catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        })*/
            .finally(() => {
                setIsDownloading(false);
            });
    };

    const [ImageDialogOpen, setImageDialogOpen] = useState(false);
    const onImageClick = (event: React.MouseEvent) => {
        setImageDialogOpen(true);
    };

    return (
        <BootstrapGrid
            onClick={onImageClick}
            direction="row"
            alignContent="center"
            justifyContent="space-between"
            container>
            <DownloadButtonGrid alignContent="center" item>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
                    {isDownloading ? (
                        <CircularProgress size={30} variant="indeterminate" />
                    ) : (
                        <IconButton onClick={onDownload}>
                            <FontAwesomeIcon icon={faDownload} />
                        </IconButton>
                    )}
                </Box>
            </DownloadButtonGrid>
            <Grid xs alignContent="center" item>
                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                    <FontAwesomeIcon icon={icon} /> &nbsp; {name}
                </Box>
            </Grid>
            <Grid alignContent="center" item>
                <Box display="flex" justifyContent="left" alignItems="center" minHeight="100%">
                    {humanFileSize(props.file.size)}
                </Box>
            </Grid>
            {props.file.type === AssetFileType.Image ? (
                <ImageDialog
                    imageSrc={props.file.location}
                    open={ImageDialogOpen}
                    title={props.file.name}
                    onCancel={() => {
                        setImageDialogOpen(false);
                    }}
                />
            ) : undefined}
        </BootstrapGrid>
    );
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes: number, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + " " + units[u];
}
