import {AssetFile, AssetFileType, FileUploadStatus} from "../Interfaces/Asset"
import { FileUploader } from "./FileUploader";
import { v4 as uuidv4 } from "uuid";

export class FileHelper {
    private _fileId: number = Infinity;
    public get fileId(): number {
        return this._fileId;
    }

    private _uploadProgress: number = 0;
    public get uploadProgress(): number {
        return this._uploadProgress;
    }

    private _uploadStatus: FileUploadStatus = FileUploadStatus.QUEUED;
    public get uploadStatus(): FileUploadStatus {
        return this._uploadStatus;
    }

    private _file: File;

    public get file(): File {
        return this._file;
    }

    constructor(file: File){
        this._file = file;
    }

    private _subscribers: {[key:string]: ()=>void} = {};
    private updateSubscribers(): void {
        for (const [, callback] of Object.entries(this._subscribers)){
            callback();
        }
    }
    
    async upload(): Promise<AssetFile>{
        let uploader = FileUploader.getInstance();
        return await uploader.uploadFile(this);
    }

    getType():AssetFileType {
        switch (this.getExtension()){
            case 'stl':
            case 'obj':
                return AssetFileType.Model;
            case 'png':
            case 'jpg':
            case 'jpeg':
            case 'gif':
                return AssetFileType.Image;
            case 'chitubox':
            case 'lyt':
            case 'lys':
                return AssetFileType.Slicer;
            default:
                return AssetFileType.Other;
        }
    }

    getName():string {
        let split = this._file.name.split('.');
        split.splice(split.length-1);
        return split.join('.');
    }

    getExtension():string {
        let split = this._file.name.split('.');
        return split.slice(split.length-1)[0];
    }

    bind(fileId: number){
        this._fileId = fileId;
    }

    uploadStatusUpdate(progress: number, status: FileUploadStatus){
        this._uploadProgress = progress;
        this._uploadStatus = status;

        this.updateSubscribers();
    }

    subscribe(callback:()=>void): string {
        let subId = uuidv4();
        this._subscribers[subId] = callback;
        return subId;
    }

    unsubscribe(subId: string){
        delete this._subscribers[subId];
    }
}