import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import AssetUploadDialog from "../Dialogs/AssetUploadDialog";
import { Asset, AssetFile } from "../Interfaces/Asset";
import APIHelper from "../Helpers/APIHelper";
import AssetDialog from "../Dialogs/AssetDialog";
const apiHelper = APIHelper.getInstance();

const WideButton = styled(Button)`
    width: 100%;
`;

export interface AssetEditButtonProps {
    asset: Asset;
}

export default function AssetEditButton(props: AssetEditButtonProps) {
    const [asset, setAsset] = useState<Asset>({ ...props.asset });

    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

    const onAssetDialogCancel = () => {
        setUploadDialogOpen(false);
    };

    const onAssetDialogSave = () => {
        setUploadDialogOpen(false);

        apiHelper.updateFiles(asset.files).then(() => {
            apiHelper.updateAsset(asset);
        });
    };

    const openAssetDialog = () => {
        setUploadDialogOpen(true);
    };

    const onAssetChange = (newAsset: Asset) => {
        setAsset(newAsset);
    };

    return (
        <React.Fragment>
            <WideButton
                variant="contained"
                onClick={(event) => {
                    event.stopPropagation();
                    openAssetDialog();
                }}>
                <FontAwesomeIcon icon={faCog} /> &nbsp; Edit
            </WideButton>
            <AssetUploadDialog
                title="Edit asset"
                asset={asset}
                open={uploadDialogOpen}
                onCancel={onAssetDialogCancel}
                onSave={onAssetDialogSave}
                onChange={onAssetChange}
            />
        </React.Fragment>
    );
}
