import React from 'react'
import { Grid } from '@mui/material';
import AccountHelper from '../Helpers/AccountHelper';
import { styled } from '@mui/system';
import emotion from '@emotion/styled'

const accountHelper = new AccountHelper();

const BootstrapContainerGrid = styled(Grid)`
    background-color: #fff;
    border-radius: 2px 2px 2px 2px;
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.60);
    cursor: pointer;
    max-width: 250px;
`;

const IconGridItem = styled(Grid)`
    width: 40;
    height: 40;
`;

const IconImg = emotion.img`
    width: 40;
    height: 40;
`;

const TextGridItem = styled(Grid)(({theme}) => `
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(2)};
    color: #757575;
    font-weight: 600;
`)

const LogoutLink = emotion.a`
    color: #48dbfb;
`;

export default function GoogleLoginComponent() {
    accountHelper.refreshToken();
    let name = accountHelper.getUser().name;

    let handleLoginClick = () => {
        window.location.href = "https://api.tabletop.cloud/account/login?redirect=modelsdb";
    }

    let handleLogoutClick = () => {
        accountHelper.logOut();
        window.location.href = "https://api.tabletop.cloud/account/logout?redirect=modelsdb";
    }

    let loginButton = 
        <BootstrapContainerGrid onClick={handleLoginClick} direction="row" alignItems="center" spacing={0} container>
            <IconGridItem item>
                <IconImg alt="Google Logo" src="images/btn_google.svg"/>
            </IconGridItem>
            <TextGridItem item>
                Sign in with Google
            </TextGridItem>
        </BootstrapContainerGrid>;

    let logoutButton = <div>You are logged in as {name}! <LogoutLink onClick={handleLogoutClick} href="#">Logout?</LogoutLink></div>;

    return (
        <div>
            {(accountHelper.isLoggedIn())? logoutButton:loginButton}
        </div>
    )
}
